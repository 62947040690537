<template>
    <div class="text-left">
        <v-dialog
                v-model="dialogDateFormat"
                width="600"
                scrollable
        >
            <template v-slot:activator="{ on, attrs  }">
               {{languagePack('reportTemplates','labelDateAccording')}} <a
                   v-bind="attrs"
                   v-on="on"
                   title="Date Format"
                  color="blue"
                > this format
                </a>
            </template>

            <v-card  max-height="500px">
                <v-card-title class="headline-box-headline grey lighten-2">
                    Date Format
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col></v-col>
                        <v-col class="text-subtitle-1">Token</v-col>
                        <v-col class="text-subtitle-1">Output</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Month</v-col>
                        <v-col>M</v-col>
                        <v-col>1 2 ... 11 12</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>Mo</v-col>
                        <v-col>1st 2nd ... 11th 12th</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>MM</v-col>
                        <v-col>01 02 ... 11 12</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>MMM</v-col>
                        <v-col>Jan Feb ... Nov Dec</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>MMMM</v-col>
                        <v-col>January February ... November December</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Quarter</v-col>
                        <v-col>Q</v-col>
                        <v-col>1 2 3 4</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>Qo</v-col>
                        <v-col>1st 2nd 3rd 4th</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Day of Month</v-col>
                        <v-col>D</v-col>
                        <v-col>1 2 ... 30 31</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>Do</v-col>
                        <v-col>1st 2nd ... 30th 31st</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>DD</v-col>
                        <v-col>01 02 ... 30 31</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Day of Year</v-col>
                        <v-col>DDD</v-col>
                        <v-col>1 2 ... 364 365</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>DDDo</v-col>
                        <v-col>1st 2nd ... 364th 365th</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>DDDD</v-col>
                        <v-col>001 002 ... 364 365</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Day of Week</v-col>
                        <v-col>d</v-col>
                        <v-col>0 1 ... 5 6</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>do</v-col>
                        <v-col>0th 1st ... 5th 6th</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>dd</v-col>
                        <v-col>Su Mo ... Fr Sa</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>ddd</v-col>
                        <v-col>Sun Mon ... Fri Sat</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>dddd</v-col>
                        <v-col>Sunday Monday ... Friday Saturday</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Day of Week (Locale)</v-col>
                        <v-col>e</v-col>
                        <v-col>0 1 ... 5 6</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Day of Week (ISO)</v-col>
                        <v-col>E</v-col>
                        <v-col>1 2 ... 6 7</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Week of Year</v-col>
                        <v-col>w</v-col>
                        <v-col>1 2 ... 52 53</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>wo</v-col>
                        <v-col>1st 2nd ... 52nd 53rd</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>ww</v-col>
                        <v-col>01 02 ... 52 53</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Week of Year (ISO)</v-col>
                        <v-col>W</v-col>
                        <v-col>1 2 ... 52 53</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>Wo</v-col>
                        <v-col>1st 2nd ... 52nd 53rd</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>WW</v-col>
                        <v-col>01 02 ... 52 53</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Year</v-col>
                        <v-col>YY</v-col>
                        <v-col>70 71 ... 29 30</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>YYYY</v-col>
                        <v-col>1970 1971 ... 2029 2030</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>Y</v-col>
                        <v-col>1970 1971 ... 9999 +10000 +10001
                            <br>
                            <p class="font-weight-bold">Note:</p> This complies with the ISO 8601 standard for dates past the year 9999
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Week Year</v-col>
                        <v-col>gg</v-col>
                        <v-col>70 71 ... 29 30</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>gggg</v-col>
                        <v-col>1970 1971 ... 2029 2030</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Week Year (ISO)</v-col>
                        <v-col>GG</v-col>
                        <v-col>70 71 ... 29 30</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>GGGG</v-col>
                        <v-col>1970 1971 ... 2029 2030</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">AM/PM</v-col>
                        <v-col>A</v-col>
                        <v-col>AM PM</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>a</v-col>
                        <v-col>am pm</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Hour</v-col>
                        <v-col>H</v-col>
                        <v-col>0 1 ... 22 23</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>HH</v-col>
                        <v-col>00 01 ... 22 23</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>h</v-col>
                        <v-col>1 2 ... 11 12</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>hh</v-col>
                        <v-col>01 02 ... 11 12</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>k</v-col>
                        <v-col>1 2 ... 23 24</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>kk</v-col>
                        <v-col>01 02 ... 23 24</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Minute</v-col>
                        <v-col>m</v-col>
                        <v-col>0 1 ... 58 59</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>mm</v-col>
                        <v-col>00 01 ... 58 59</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Second</v-col>
                        <v-col>s</v-col>
                        <v-col>0 1 ... 58 59</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>ss</v-col>
                        <v-col>00 01 ... 58 59</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Fractional Second</v-col>
                        <v-col>S</v-col>
                        <v-col>0 1 ... 8 9</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>SS</v-col>
                        <v-col>00 01 ... 98 99</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>SSS</v-col>
                        <v-col>000 001 ... 998 999</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>SSSS ... SSSSSSSSS</v-col>
                        <v-col>000[0..] 001[0..] ... 998[0..] 999[0..]</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Time Zone</v-col>
                        <v-col>z or zz</v-col>
                        <v-col>
                            EST CST ... MST PST
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>Z</v-col>
                        <v-col>-07:00 -06:00 ... +06:00 +07:00</v-col>
                    </v-row>
                    <v-row>
                        <v-col></v-col>
                        <v-col>ZZ</v-col>
                        <v-col>
                            -0700 -0600 ... +0600 +0700
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Unix Timestamp</v-col>
                        <v-col>X</v-col>
                        <v-col>1360013296</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="font-weight-bold">Unix Millisecond Timestamp</v-col>
                        <v-col>x</v-col>
                        <v-col>1360013296123</v-col>
                    </v-row>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            text
                            @click="dialogDateFormat = false"
                    >
                        close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
  export default {
    data () {
      return {
        dialogDateFormat: false,
      };
    },
  }
</script>
