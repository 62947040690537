<template>
  <div class="reportTemplate">
    <div class="padL15 padB15 imageHolder">
      <div
        v-if="result.report.editable==false"
        class="draggablearea"
        style="max-width:525px;min-width:525px;height:45px!important;max-height:45px !important;"
      >
        <div class="imageplace draggable" style="position: relative;">
          <img style="display:inline-block;height:45px;max-width:524.5px;" class="resizable" src />
        </div>
      </div>

      <div v-if="result.report.editable==true">
        <v-card>
          <v-card-title>
            <h4 class="rtTitles">{{languagePack('reportTemplates','headerImage')}}</h4>
          </v-card-title>
          <v-card-text>
            <div class="contentAlign">
              {{languagePack('reportTemplates','headerText')}}
              <br /> {{languagePack('reportTemplates','labelHeaderImgFormat')}}
              <br /> {{languagePack('reportTemplates','labelHeaderImgSize')}}
            </div>

            <!-- <input
                name="headerImageSrc"
                :class="fileClasses"
                type="file"
                error-msg="image file"
                accept=".jpg, .jpeg, .png"
                style="width:60%;"
                help-data="{help('report','uploadImage')}"
            />-->

            <fileReader
              v-model="file"
              @load="validateImage(...arguments)"
              :fieldAttributes="{fieldAttrInput:{'placeholder':languagePack('reportTemplates','browseButton')}}"
            ></fileReader>

            <span
              style="position: absolute;font-size: 12px;top:-9px;"
            >{{languagePack('reportTemplates','imgeConditions')}}</span>

            <div class="imageHolder">
                <div
                  class="imageplace required ml-2"
                  style="position: relative; border: 1px solid rgba(128, 128, 128, 0.7); height: 48px; max-width: 99%"
                >
                  <img
                    style="display:inline-block;max-height:45px;"
                    status="none"
                    class="sourceHeaderImg required"
                    :src="imageSource"
                  />
                </div>

            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div class="padL15 timeFormatter" style="margin-top: 0.3em">
      <div>
        <!-- <div v-if="result.report.editable==true"> -->
        <v-card>
          <v-card-title>
            <h4 class="rtTitles">{{languagePack('reportTemplates','timeFormatHeader')}}</h4>
          </v-card-title>
          <v-card-subtitle>
             {{languagePack('reportTemplates','timeContent')}}
             <dateFormatInfo></dateFormatInfo>
          </v-card-subtitle>
          <v-card-text>
            <div>
              <div>
                <!-- <span style="padding-right:55px;">{{languagePack('reportTemplates','dataFormat')}}</span> -->
                <!-- 
                <input
                  v-if="result.report.editable==true"
                  type="text"
                  class="timeFormatText momentFormat"
                  onkeyup="changeTime(this);"
                  help-data="{help('report','dataFormat')}"
                />

                <label v-else type="text" class="timeFormatText momentFormat"></label>

                <label class="timeLabel"></label>-->
                <momentDateFormatter
                  :result="result"
                  v-model="timeformat"
                ></momentDateFormatter>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <div class="ui-widget-content padB15" style="margin-top: 0.3em;">
      <v-card>
        <v-card-title>
          <h4 class="rtTitles padL15">{{languagePack('reportTemplates','additionalInfo')}}</h4>
        </v-card-title>

        <v-card-text>
          <div v-if="result.report.editable==true">
            <primaryTextfield
              v-model="headTitle"
              :fieldAttrInput="{}"
              :label="languagePack('reportTemplates','headerTitle')"
            ></primaryTextfield>
          </div>
          <div style=" width:34.7em;margin-left: 15px">
            <table class="reportDiscFields" style="width:100%;margin:5px 5px 5px 5px;">
              <tbody class="sortable"></tbody>
            </table>

            <reportAdditionalData :reportTemplatevalue="reportTemplatevalue" v-on="$listeners"></reportAdditionalData>
            <!-- <div v-if="result.report.editable==true"> -->
            <!-- <lineFieldsArray
                :fieldAttrRow="{}"
                :fields="fields"
                :templateContent="{}"
                :value="pairArray"
                @line-field-input="checkInput(...arguments)"
                :headerTitle="true"
                addButtTitle="Add Field"
            ></lineFieldsArray>-->
            <!-- </div> -->
          </div>

          <div v-if="result.report.editable==true">
            <v-card outlined class="mt-3">
              <v-card-title>
                <h4 class="rtTitles padL15">{{languagePack('reportTemplates','includeStatus')}}</h4>
              </v-card-title>
              <v-card-subtitle>
                <div class="contentAlign padL15">{{languagePack('reportTemplates','statusContent')}}</div>
              </v-card-subtitle>
              <v-card-text>
                <checkbox
                  :fieldAttrInput="{}"
                  :fieldAttributes="{name:'Error'}"
                  :value="include.error"
                  @input="updateInclude('error')"
                  :hideDetails="true"
                ></checkbox>
                <checkbox
                  :fieldAttrInput="{}"
                  :fieldAttributes="{name:'Warnning'}"
                  :value="include.warnning"
                  @input="updateInclude('warnning')"
                  :hideDetails="true"
                ></checkbox>
                <checkbox
                  :fieldAttrInput="{}"
                  :fieldAttributes="{name:'OK'}"
                  :value="include.ok"
                  @input="updateInclude('ok')"
                  :hideDetails="true"
                ></checkbox>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <noticeDialog v-model="showDialog" title="Notice" :message="message"></noticeDialog>
  </div>

  <!-- <dateFormatInfo></dateFormatInfo> -->
</template>
<script>
import dateFormatInfo from "@/components/legacy/dateFormatInfo.vue";
import reportAdditionalData from "@/components/legacy/reportAdditionalData.vue";
import noticeDialog from "@/commonComponents/noticeDialog.vue";
import fileReader from "@/commonComponents/fileReader.vue";
import momentDateFormatter from "@/components/legacy/momentDateFormatter.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import lineButtons from "@/cfg/lineButtons.json";

import checkbox from "@/commonComponents/checkbox.vue";
export default {
  props: ["result", "value"],
  data() {
    return {
      file: null,
      message: "",
      showDialog: false,
      imageSource: "",

      fields: {
        name: {
          type: "text",

          name: "Attribute",
        },
        value: {
          type: "text",

          name: "Value",
        },
        remove: lineButtons.remove,
      },
    };
  },
  components: {
    dateFormatInfo,
    fileReader,
    noticeDialog,
    momentDateFormatter,
    primaryTextfield,
    checkbox,
    reportAdditionalData,
  },
  created() {
    if (typeof this.value.headerImg != "undefined") {
      this.imageSource = this.value.headerImg.imgURL;
    }
  },
  computed: {
    include() {
      let include = {};
      if (typeof this.reportTemplatevalue["include"] != "undefined") {
        for (let [key, val] of Object.entries(
          this.reportTemplatevalue["include"]
        )) {
          let value = 0;
          if (val == "true") {
            value = 1;
          }
          include[key] = value;
        }
      }
      return include;
    },
    reportTemplatevalue() {
      return this.value;
    },
    pairArray: {
      get() {
        let discFields = [];
        if (typeof this.reportTemplatevalue["discFields"] != "undefined") {
          discFields = this.reportTemplatevalue["discFields"];
        }
        return discFields;
      },
      set(newVal) {
        this.$set(this.reportTemplatevalue, "discFields", newVal);
        this.$emit("input", this.reportTemplatevalue);
      },
    },
    headTitle: {
      get() {
        let headTitle = "";
        if (typeof this.reportTemplatevalue["headTitle"] != "undefined") {
          headTitle = this.reportTemplatevalue["headTitle"];
        }
        return headTitle;
      },
      set(newVal) {
        this.$set(this.reportTemplatevalue, "headTitle", newVal);
        this.$emit("input", this.reportTemplatevalue);
      },
    },
    timeformat: {
      get() {
        let timeformat = "DD.MM.YYYY HH:mm:ss";
        if (typeof this.reportTemplatevalue["timeformat"] != "undefined") {
          timeformat = this.reportTemplatevalue["timeformat"];
        }
        return timeformat;
      },
      set(newVal) {
        this.$set(this.reportTemplatevalue, "timeformat", newVal);
        this.$emit("input", this.reportTemplatevalue);
      },
    },
    fileClasses() {
      let validateClass = " required";
      if (
        this.result.additionlFormAtts["data-callingFunction"] ==
        "getInsertedFieldsEdit"
      ) {
        validateClass = "";
      }
      return "fileUpload" + validateClass;
    },
  },
  methods: {
    updateInclude(name, newVal) {
      let value = "false";
      if (newVal == 1) {
        value = "true";
      }
      this.$set(this.reportTemplatevalue.include, name, value);
      this.$emit("input", this.reportTemplatevalue);
    },
    checkInput(newVal) {
      this.$set(this.reportTemplatevalue, "discFields", newVal);
      this.$emit("input", this.reportTemplatevalue);
    },
    //Preview the image before upload!
    readURL(file) {
      console.log(file);
      //var fatherDiv = $(input).closest('.imageHolder');

      //  let result = fatherDiv.find('.sourceHeaderImg').attr('status');

      // var reader = new FileReader();
      // reader.onload = function (e) {
      //   //  var $imageplace=fatherDiv.find('.imageplace');

      //   // var img = $("<img style='width:auto;display:inline-block;max-height: 45px;max-width:545px;height:45px;width:545px;' status='none' class='sourceHeaderImg required ' />").attr('src', e.target.result);
      //   //   $imageplace.html(img);
      //   this.imageSource = e.target.result;
      //    console.log(this.imageSource);
      // };
      // reader.readAsDataURL(file);
      this.imageSource = URL.createObjectURL(file);
    },

    validateImage() {
      this.imageSource = "";
      //console.log(file);
      var file = this.file;
      if (typeof file != "undefined") {
        if (file.size > 10000000) {
          this.message = "File is too large!";
          this.showDialog = true;
          this.file = null;
        } else if (
          file.type != "image/png" &&
          file.type != "image/jpg" &&
          file.type != "image/jpeg"
        ) {
          this.message = "File doesn't match png or jpg";
          this.showDialog = true;
          this.file = null;
        } else {
          var data = new FormData();
          data.append("file", file);
          data.append("function", "uploadImageCheck");
          //   let $file = $(this);
          //let $fieldsObject = $file.closest(".fields");
          let caller = this;
          this.frameworkAxiosRequest({
            method: 'post',
            url: 'serve.php?f=configuration&f2=reportTemplates',
            data: data,
            //headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
          })
              .then(function (response) {
              let data = response.data.result.json;
              console.log(data);
              // caller.visible = true;
              // var imgPointer = $file
              //   .closest(".imageHolder")
              //   .find(".sourceHeaderImg");
              // imgPointer.attr("status", "fail");
              if (typeof caller.reportTemplatevalue.headerImg == "undefined") {
                caller.$set(caller.reportTemplatevalue, "headerImg", {});
              }
              caller.$set(
                caller.reportTemplatevalue.headerImg,
                "status",
                "fail"
              );
              caller.$emit("input", caller.reportTemplatevalue);
              if (typeof data.error === "undefined") {
                if (data.result.result == "success") {
                  //check result of upload before
                  // imgPointer.attr("status", "success");
                  // $file
                  //   .closest(".imageHolder")
                  //   .find(".fileUpload")
                  //   .removeClass("required");

                  caller.$set(
                    caller.reportTemplatevalue.headerImg,
                    "status",
                    "success"
                  );
                  caller.$emit("input", caller.reportTemplatevalue);

                  caller.readURL(file);
                  caller.$emit("file-upload", "headerImageSrc", caller.file);
                } else {
                  caller.message = data.result.code;
                  caller.showDialog = true;
                  caller.file = null;
                  // if (
                  //   $file
                  //     .closest(".imageHolder")
                  //     .find(".sourceHeaderImg")
                  //     .attr("src") == ""
                  // ) {
                  //   file = {};
                  //   $file.closest(".imageHolder").find(".fileUpload").val(null); //make null then only required will work
                  // }
                  // $file
                  //   .closest(".imageHolder")
                  //   .find(".fileUpload")
                  //   .addClass("required");
                  // showSimpleNotice(data.result.code, 550, "Notice");
                }
              } else {
                caller.file = null;
                // if (
                //   $file
                //     .closest(".imageHolder")
                //     .find(".sourceHeaderImg")
                //     .attr("src") == ""
                // ) {
                //   $file.closest(".imageHolder").find(".fileUpload").val(null); //make null then only required will work
                // } // Handle errors here
                // console.log("ERRORS: " + data.error);
              }
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });

          // $.ajax({
          //   type: "POST",
          //   data: data,
          //   cache: false,
          //   url: "serve.php?f=configuration&f2=reportTemplates",
          //   dataType: "json",
          //   processData: false, // Don't process the files
          //   contentType: false, // Set content type to false as jQuery will tell the server its a query string request
          //   success: function (data, textStatus, jqXHR) {
          //     var imgPointer = $file
          //       .closest(".imageHolder")
          //       .find(".sourceHeaderImg");
          //     imgPointer.attr("status", "fail");
          //     if (typeof data.error === "undefined") {
          //       if (data.result.result == "success") {
          //         //check result of upload before
          //         imgPointer.attr("status", "success");
          //         $file
          //           .closest(".imageHolder")
          //           .find(".fileUpload")
          //           .removeClass("required");
          //         readURL($file, file);
          //       } else {
          //         if (
          //           $file
          //             .closest(".imageHolder")
          //             .find(".sourceHeaderImg")
          //             .attr("src") == ""
          //         ) {
          //           file = {};
          //           $file.closest(".imageHolder").find(".fileUpload").val(null); //make null then only required will work
          //         }
          //         $file
          //           .closest(".imageHolder")
          //           .find(".fileUpload")
          //           .addClass("required");
          //         showSimpleNotice(data.result.code, 550, "Notice");
          //       }
          //     } else {
          //       if (
          //         $file
          //           .closest(".imageHolder")
          //           .find(".sourceHeaderImg")
          //           .attr("src") == ""
          //       ) {
          //         $file.closest(".imageHolder").find(".fileUpload").val(null); //make null then only required will work
          //       } // Handle errors here
          //       console.log("ERRORS: " + data.error);
          //     }
          //   },
          //   error: function (jqXHR, textStatus, errorThrown) {
          //     // Handle errors here
          //     // STOP LOADING SPINNER
          //   },
          // });
        }
      }
    },
  },
};
</script>
<!--script>
$(document).ready(function () {
  let fileUpload = $(".fileUpload");
  let custom = $(".fileUploadCustom");
  custom.bind("click", function () {
    fileUpload.trigger("click");
    return false;
  });

  $(".getTimeFormat").click(function () {
    let $caller = $(this);
    let $returnedDialog = $(".dateDisplayFormat").dialog({
      modal: false,
      buttons: {
        Ok: function () {
          $(this).dialog("close");
        },
      },
      width: "400px",
    });
    $returnedDialog = dialogStackUp($caller, $returnedDialog);
  });
});



"use strict";
function removeAdditionalField(that){
  $(that).closest('tr').remove();
}

function loadReportTemplate(fieldsObject,reportTemplate){
  $('.scrolling').css( "max-width", "960px" );//stabilize the width
  if (typeof reportTemplate['headTitle']!="undefined"){
    fieldsObject.find('.rht .headTitle').val(reportTemplate['headTitle']);
  }

    if (typeof reportTemplate['headerImg']!="undefined"){
    var $imageplace=fieldsObject.find('.imageplace');
    var $pdfImage=fieldsObject.find('.imageplace img');
    if ($pdfImage.hasClass("ui-resizable")){
      $pdfImage.resizable('destroy');
    }
    let exitImgPath  =  fieldsObject.find('.imagePathField').val(reportTemplate['headerImg']['imgURL']);
    var notice = '<div class="notice">Something went wrong!, We\'re working on it!</div>';
    let alignImg =  fieldsObject.find('.imgAlign').val(reportTemplate['imgAlign']);
    if(typeof reportTemplate['headerImg']['imgURL']!="undefined" && reportTemplate['headerImg']['imgURL']!="") {
        var img = $("<img style='width:auto;display:inline-block;max-height: 45px;max-width:545px;height:45px;width:545px;' status='none' class=' sourceHeaderImg required' />").attr('src', reportTemplate['headerImg']['imgURL'])
          .on("load", function () {
             if (!this.complete || typeof this.naturalWidth == "undefined" || this.naturalWidth == 0) {
              $imageplace.html(notice);
            } else {
              let width=545;
              let height=45;
              if (reportTemplate['headerImg']['width']>50){
                width=reportTemplate['headerImg']['width'];
                height=reportTemplate['headerImg']['height'];
              }
              img.width(width);
              img.height(height);
              $imageplace.css('left', reportTemplate['headerImg']['left']);
              $imageplace.css('top', reportTemplate['headerImg']['top']);
              let result = $imageplace.find('.sourceHeaderImg').attr('status');
                $imageplace.html(img);
              //refresh(fieldsObject);
              img.width(width);
              img.height(height);
              let imgName = reportTemplate['headerImg']['imgName'];
              if(typeof reportTemplate['headerImg']['imgName']=="undefined"){
                let imgPro = reportTemplate['headerImg']['imgURL'].split('/');
                imgName = imgPro[1];
              }
              img.attr('name',imgName);
             // refreshHeaderImage(fieldsObject);
            }
          });
    }else{
      //if any issue in imgURL
      $imageplace.html(notice);
    }
  }
  fieldsObject.find('.reportDiscFields').html("");
  if (typeof reportTemplate['discFields']!="undefined"){
    $.each(reportTemplate.discFields,function(index,value){
      let textLine=getTextHtml(value,fieldsObject.data( "fonts"),fieldsObject.data( "fontSizes"));
      fieldsObject.find('.reportDiscFields').append(textLine);
    });

  }if (typeof reportTemplate['include']=="undefined"){
    reportTemplate['include']={"error":"false","warnning":"false","ok":"false"};
  }
  
  fieldsObject.data("timeformat","DD.MM.YYYY HH:mm:ss");
  if (typeof reportTemplate['timeformat']!="undefined"){
    fieldsObject.data("timeformat",reportTemplate['timeformat']);
  }
  fieldsObject.find('.timeFormatText').val(fieldsObject.data("timeformat"));
  fieldsObject.find('.timeFormatText').text(fieldsObject.data("timeformat"));
  fieldsObject.find('.includeCheck tbody').html("");
  $.each(reportTemplate.include,function(index,value){
    var checked="";
    if (value=="true"){
      checked="checked=checked"
    }
    let $textLine=$("<tr><td class='checkindex'style='width: 15em;text-transform: capitalize;'>"+index+"</td><td><input class='checkvalue' type='checkbox' "+checked+"></input></td></tr>");
    fieldsObject.find('.includeCheck tbody').append($textLine);
  });
  
  if (typeof reportTemplate['showedAttributes']!="undefined"){
    var $showedAttributes=fieldsObject.find('.showedAttributes');
    $.each(reportTemplate['showedAttributes'],function(index,value){
      addShowedAttributestoDiv($showedAttributes,value);
    })
  }
 /* if (typeof reportTemplate['externalComment']!="undefined" && reportTemplate['externalComment']=="true"){
    fieldsObject.find('.externalComment').prop('checked', true);
  }*/
  resizeForm(fieldsObject);
}

function addTemplateField(that){
  var $fieldsObject=$(that).closest(".fields");
  let textLine=getTextHtml("",$fieldsObject.data( "fonts"),$fieldsObject.data( "fontSizes"));
  $(that).closest(".reportTemplate").find('.reportDiscFields').append(textLine);
}






/*$(".fileUpload").change(function(){
  let $fieldsObject=$(this).closest(".fields")
  refreshHeaderImage($fieldsObject);
     readURL(this);
  });*/

/*
function loadHeaderImageURL(that){
  var fatherDiv=$(that).closest('.imageHolder');
  var imagePath=fatherDiv.find(".imagePathField").val();
  console.log(that)
  var img = fatherDiv.find('.sourceHeaderImg').attr('src', imagePath)
    .on("load",function() {
      if (!this.complete || typeof this.naturalWidth == "undefined" || this.naturalWidth == 0) {
        showSimpleNotice('Broken image!','','Notice');
      } else {
        var myImage = new Image();
        myImage.src = imagePath;
        img.width(myImage.width);
        img.height(myImage.height);
        fieldsObject.find('.imageplace').css('left',  0);
        fieldsObject.find('.imageplace').css('top',  0);
        fatherDiv.find(".imageplace").html(img);
        refreshHeaderImage(fieldsObject);
        img.width(myImage.width);
        img.height(myImage.height);
      }
    });
}
*/
// function findHHandWW(fatherDiv,img) {
  // console.log(this.height,this.width);
    // img.css( "height", this.height);
    // img.css( "width", this.width);
     // fatherDiv.find(".imageplace").html(img);
      // var fieldsObject=$(fatherDiv).closest(".fields");
      // refresh(fieldsObject);
    // return true;
  // }

function parseObj(fieldsObject, data) {
  var reportTemplateValue = {};
  reportTemplateValue['headerImg'] = {};
  reportTemplateValue['headerImg']['imgName'] = fieldsObject.find('.imageplace img').attr('name');
  if(fieldsObject.find('.imagePathField').val()!=""){
    //reportTemplateValue['headerImg']['url'] = fieldsObject.find('.imagePathField').val();
  }
  reportTemplateValue['headerImg']['height'] = fieldsObject.find('.imageplace img').height();
  reportTemplateValue['headerImg']['width'] = fieldsObject.find('.imageplace img').width();
  reportTemplateValue['headerImg']['top'] = fieldsObject.find('.imageplace').css("top");
  reportTemplateValue['headerImg']['left'] = fieldsObject.find('.imageplace').css("left");
  reportTemplateValue['headerImg']['status'] = fieldsObject.find('.imageplace img').attr('status');
  // reportTemplateValue['externalComment'] = fieldsObject.find('.externalComment').prop('checked');
  let imgAlignment="left";
/*  if(fieldsObject.find('.imgAlign').val()!=""){
    imgAlignment = fieldsObject.find('.imgAlign').val();
  }*/
  reportTemplateValue['imgAlign'] =imgAlignment;
  var discFields = [];
  fieldsObject.find(".reportTemplate").find(".sortableElement").each(function () {
    var fieldtitle = $(this).find('.fieldtitle input').val();
    if (typeof fieldtitle == "undefined") {
      fieldtitle = $(this).find('.fieldtitle').text();
    }
    if (fieldtitle != "") {
      var fieldValue = $(this).find('.fieldValue input').val();
      discFields.push({"value": fieldValue, "name": fieldtitle});
    }
  });
  var checks = {};
  fieldsObject.find(".reportTemplate").find(".includeCheck tr").each(function () {
    var fieldtitle = $(this).find('.checkindex').text();
    var value = "false";
    var fieldValue = $(this).find('.checkvalue').is(':checked');
    if (fieldValue == true) {
      value = "true";
    }
    checks[fieldtitle] = value;
  });
  let reportTitle = fieldsObject.find('.rht .headTitle').val();
  var showedAttributesArray = [];
  var $showedAttributes = fieldsObject.find('.showedAttributes');
  if ($showedAttributes.length > 0) {
    $showedAttributes.find("select.singleShowedAttribute").each(function () {
      showedAttributesArray.push($(this).val());
    });
  }
  reportTemplateValue['timeformat'] = fieldsObject.find('.timeFormatText').val();
  reportTemplateValue['discFields'] = discFields;
  reportTemplateValue['include'] = checks;
  reportTemplateValue['showedAttributes'] = showedAttributesArray;
  reportTemplateValue['headTitle'] = reportTitle;
   data["c_obj"] = reportTemplateValue;
  return data;
}

function updateTime(fieldsObject){
  let dateFormat = fieldsObject.find('.timeFormatText').val();
  fieldsObject.find('.timeLabel').text(moment().format(dateFormat));
}

function getFontsDiv(fontsArray, fontSizesArray) {
      return "font:" + makeSelector(fontsArray, "", "fonttype") + "size:" + makeSelector(fontSizesArray, "", "fontsize");
    }

function getTextHtml(value, fontsArray, fontSizesArray) {
      if (value == "") {
        value = { name: "", value: ""};
      }

      var nameValue = htmlspecialchars(value.name);
      if (nameValue =="" || typeof nameValue== 'undefined') {
        nameValue =buildInput("",{"class":"reportTempTxtField tPlus"});
       // nameValue = "<input type='text' class='reportTempTxtField tPlus'/> ";
      }
      //var textLine='<div class="sortableElement" style="padding:3px;"><b class="fieldtitle">'+nameValue+'</b>:<span class="fieldValue">'+value.type+'</span>  <span class="fontselector">'+getFontsDiv(fontsArray,fontSizesArray)+'</span></div>';
      let textLine = '<tr class="sortableElement" style="padding:3px;"><td><a href="javascript:void(0);" onclick="removeAdditionalField(this);"><i class="fas fa-trash fs16 iconRed" title="Remove" ></i></a></td><td style="width:16.5em;"><b class="fieldtitle">' + nameValue + '</b>:</td><td class="fieldValue">'+buildInput(value.value,{"style":"width:200px;"})+' </td></tr>';
      return textLine;
    }

function refreshHeaderImage($fieldsObject) {
      $fieldsObject.find('.draggable').each(function () {
        if ($(this).hasClass("ui-draggable")) {
          $(this).draggable('destroy');
        }
      });
      $fieldsObject.find('.draggable').draggable({ containment: "parent"});
       $fieldsObject.find('.resizable').each(function () {
        if ($(this).hasClass("ui-resizable")) {
          $(this).resizable('destroy');
        }
      });
       $fieldsObject.find(".resizable").each(function () {
        var $resizable = $(this);
        //var width =$resizable.width();
        // console.log($resizable.width(),$resizable.height());
        $resizable.resizable({
         /* maxHeight: 45,
          maxWidth: 545,*/
          aspectRatio: true,
          resize: function (event, ui) {
            if (ui.size.height>45){
              ui.size.height=45;
            }
            if (ui.size.width>545){
              ui.size.width=545;
            }
            $(this).closest('.imageplace').height(ui.size.height).width(ui.size.width);
            $(this).find('.sourceHeaderImg').height(ui.size.height).width(ui.size.width);
          }
        });
      });
      $fieldsObject.find(".resizable").each(function () {
        let heightImg = $(this).height();
        let widthImg = $(this).width();
        if(heightImg==0){
           heightImg = 45;
           widthImg = 545;
        }

        $(this).closest('.imageplace').height(heightImg);
        $(this).closest('.imageplace').width(widthImg);
      });
       $fieldsObject.find('.sortable').each(function () {
        if ($(this).hasClass("ui-sortable")) {
          $(this).sortable('destroy');
        }
      });
       $fieldsObject.find(".sortable").sortable();
    }

function setAlignment(that) {
      let $caller=$(that);
      var imageWidth = $caller.closest('.imageHolder').find('img').width();
      var fatherWidth =$caller.closest('.imageHolder').find('.draggablearea').width();
      var requiredAlignment = $caller.val();
      if (requiredAlignment == "left") {
        var newleft = 0;
      } else if (requiredAlignment == "middle") {
        var newleft = (fatherWidth / 2) - (imageWidth / 2);
      } else if (requiredAlignment == "right") {
        var newleft = (fatherWidth) - (imageWidth);
      }else{ //Custom
        //refreshHeaderImage($caller.closest(".fields"));
      }
      //console.log(imageWidth, fatherWidth, newleft);
     $caller.closest('.imageHolder').find('.imageplace').css("left", newleft);
    }

    function changeTime(that) {
      let $formatterInput = $(that);
      let $fieldsObject=$(this).closest(".fields");
      //window.timeformat=$formatterInput.val();
      $fieldsObject.data("timeformat", $formatterInput.val());
      ////console.log(moment().format( $formatterInput.val())  );
    }
</script-->