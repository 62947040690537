<template>
  <v-row justify="center">
    <v-dialog :max-width="maxWidth" v-model="dialog">
      <v-card class="mx-auto">
        <v-card-title class="headline-box-headline primary white--text">{{
          title
        }}</v-card-title>
        <v-card-text class="mt-5">
          <slot>{{ message }}</slot>
        </v-card-text>
        <v-card-actions v-if="action">
          <primaryButton
            type="button"
            @click="closeDialog"
            label="OK"
            icon="mdi-check"
            color="primary"
          ></primaryButton>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  components: { primaryButton },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },

  props: {
    value: Boolean,
    title: String,
    message: String,
    action: { type: Boolean, default: false },
    maxWidth: { type: String, default: "290" },
  },
};
</script>>