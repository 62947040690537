<template>
  <div>
    <reportAdditionalFieldsTemplate
      v-model="reportTemplatevalue"
      :fonts="result.additional.fonts"
      :fontSizes="result.additional.fontSizes"
      :result="result"
      @file-upload="(name, file) => $emit('file-upload', name, file)"
    ></reportAdditionalFieldsTemplate>
    <input
      type="hidden"
      :value="JSON.stringify(reportTemplatevalue)"
      name="c_obj"
    />
  </div>
</template>

<script>
import reportAdditionalFieldsTemplate from "@/components/legacy/reportAdditionalFieldsTemplate.vue";
export default {
  components: { reportAdditionalFieldsTemplate },
  props: ["result", "fieldAttributes", "value"],
  computed: {
    reportTemplatevalue: {
      get() {
        let reportTemplatevalue = "";
        reportTemplatevalue = this.result.additional.reportTemplate;
        if (
          this.isset(this.value) &&
          this.value != ""
        ) {
          reportTemplatevalue = JSON.parse(this.value);
        }
        //  console.log(reportTemplatevalue);
        return reportTemplatevalue;
      },
      set(newVal) {
        this.$emit("input", JSON.stringify(newVal));
      },
    },
  },
};
</script>
