<template>
  <v-file-input
    :accept="fieldAttributes.accept"
    :label="fieldAttributes.name"
    outlined
    v-model="chosenFile"
    :placeholder="placeholder"
    dense
    :rules="getRules(fieldAttrInput, templateContent,field)"
    truncate-length="65"
  ></v-file-input>
</template>
<script>
import { getfieldAttrInput, getPlaceholder } from "@/js/helper.js";
import { getRules } from "@/js/validators.js";
export default {
  computed: {
    fieldAttrInput() {
      return getfieldAttrInput(this.fieldAttributes);
    },
    placeholder() {
      return getPlaceholder(this.fieldAttrInput);
    },
    chosenFile: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (newValue != null && typeof newValue == "object") {
          const reader = new FileReader();
          reader.onload = (e) => this.$emit("load", e.target.result);
          reader.readAsText(newValue);        }
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    getRules: getRules,
  },
  props: {
    templateContent: {},
    value: {},
    fieldAttributes: {
      default() {
        return {};
      },
    },
    field: {},
  },
};
</script>
